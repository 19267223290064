.rs-picker-toggle .rs-picker-toggle-placeholder {
    font-size: 12px !important;
    font-family: "Roboto" !important;
    color: #344054 !important;
    line-height: 14.4px !important;
    font-weight: 400 !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    font-size: 12px !important;
    font-family: "Roboto" !important;
    color: #344054 !important;
    font-weight: 400 !important;
}

.rs-picker-default .rs-picker-toggle {
    border-radius: 4;
    border: 1px solid #D0D5DD;
    top: 10px;
    left: -10px;
}

.rs-picker-toggle-caret, .rs-picker-toggle-clean {
    color: #344054 !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}